<template>
    <div class="hoctemplate">
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt60" style="margin-top: 30px">HOC智慧办公</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                HOC辉腾管理中心，是企业一站式工作平台，完成计划、生产、运输、销售、合同、安装、验收、回款、售后、审批等综合运营管理。让工作人员畅享办公新方式，为企业提供全方位移动办公服务！
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                方案展示
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">
                辉腾能源开发的日常办公需求的运营管理系统：灵活多样的参数设置满足了多样的管理调整；分类权限管理满足的数据的保密及安全；多类终端的运用，满足了丌同使用者的需求；多渠道信息推送，防止重要信息的遗漏。
            </div>

            <div class="htable" style="margin-top: 30px;justify-content: center;width: 860px;border-bottom: 1px solid #e7e7e7;">
                <div class="hcell">
                    <span v-if="hocgn==1" class="txtH16 btLine">系统设置</span>
                    <span v-else class="txtH16 ghblockclick" @click="hocgn=1">系统设置</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hocgn==2" class="txtH16 btLine">人资行政管理</span>
                    <span v-else class="txtH16 ghblockclick" @click="hocgn=2">人资行政管理</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hocgn==3" class="txtH16 btLine">销售客户管理</span>
                    <span v-else class="txtH16 ghblockclick" @click="hocgn=3">销售客户管理</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hocgn==4" class="txtH16 btLine">项目实施管理</span>
                    <span v-else class="txtH16 ghblockclick" @click="hocgn=4">项目实施管理</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hocgn==5" class="txtH16 btLine">财务管理</span>
                    <span v-else class="txtH16 ghblockclick" @click="hocgn=5">财务管理</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="hocgn==6" class="txtH16 btLine">数据分析</span>
                    <span v-else class="txtH16 ghblockclick" @click="hocgn=6">数据分析</span>
                </div>

            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hocgn==1">
            <div  class="txt42">
                系统设置
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hoc_gl11"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl12"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl13"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                        <img :src="hoc_sys4">
                    </div>
                    <div class="hocgl_tddh">各类流程定义</div>
                    <div class="hocgl_tddt">在管理过程中的各项审批都归于流程管理，皆可通过流程定义确定各项业务处理的步骤。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_sys5">
                    </div>
                    <div class="hocgl_tddh">工作日及工作时间设定</div>
                    <div class="hocgl_tddt">在日历表中根据法定节假日设定工作和休息日，也可以根据公司需求设定工作日和工作时间。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_sys6">
                    </div>
                    <div class="hocgl_tddh">部门及职务设定</div>
                    <div class="hocgl_tddt">根据单位组织结构设定部门基本信息，可进行树状部门定义；职务设定包括职务级别值，值越小级别越高。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_sys7">
                    </div>
                    <div class="hocgl_tddh">一人多岗位设定</div>
                    <div class="hocgl_tddt">一人多职务状况在企业常有，员工跨部门职务在此设定，同一部门只能设定一个岗位，每一个员工需设定常规主要部门标。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_sys8">
                    </div>
                    <div class="hocgl_tddh">车辆管理派遣设定</div>
                    <div class="hocgl_tddt">车辆基本信息登记，车辆年检管理，车辆使用状态设定；车辆派遣审核流程定义等。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_sys9">
                    </div>
                    <div class="hocgl_tddh">分类数据字典设置</div>
                    <div class="hocgl_tddt">包括费用类别，制度档案类别、客户类别等。</div>
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hocgn==2">
            <div  class="txt42">
                人资行政管理
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hoc_gl21"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl22"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl23"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_xz4">
                    </div>
                    <div class="hocgl_tddh">公告通知</div>
                    <div class="hocgl_tddt">可定向人群(单个或多个部门与人、全公司)发布公告或通知，通过审核进行公开，可检查已阅或未阅对象。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_xz5">
                    </div>
                    <div class="hocgl_tddh">考勤定位</div>
                    <div class="hocgl_tddt">分为公司内部二维码扫码考勤，外出人员定位考勤，出差考勤等多种模式。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_xz6">
                    </div>
                    <div class="hocgl_tddh">请假调休、加班</div>
                    <div class="hocgl_tddt">根据审核流程，确定员工事假、换休、年假等流程，自动抵扣相应的换休或年假时间。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_xz7">
                    </div>
                    <div class="hocgl_tddh">制度文档</div>
                    <div class="hocgl_tddt">登记、分类公司各类规章制度及相关规范文档，根据权限分类进行授权浏览。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_xz8">
                    </div>
                    <div class="hocgl_tddh">外出办公及出差管理</div>
                    <div class="hocgl_tddt">临时外出事务处理，业务办理长时间出差等需要进行申请及费用预算，实现事务、时间、费用综合规划。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_xz9">
                    </div>
                    <div class="hocgl_tddh">用品申购</div>
                    <div class="hocgl_tddt">分为申购、审核、购买三个步骤，可根据日常易耗品、办公工具等不同类型的用品进入不同的审核流程。</div>
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hocgn==3">
            <div  class="txt42">
                销售客户管理
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                销售管理在对结果管理的同时，还注重过程及费用管理；通过过程管理分析客户需求，解决客户痛点，为客户创造价值。

            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hocgn==4">
            <div  class="txt42">
                项目实施管理
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                项目实施管理贯彻计划、过程、验收、收款全过程，对各个阶段进行监管，保证项目按时、保质完成，还对费用使用情况进行监管。
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hocgn==5">
            <div  class="txt42">
                财务管理
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hoc_gl51"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl52"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl53"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_cw4">
                    </div>
                    <div class="hocgl_tddh">支付管理</div>
                    <div class="hocgl_tddt">支付管理主要针对供应商货款支付。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_cw5">
                    </div>
                    <div class="hocgl_tddh">开票管理</div>
                    <div class="hocgl_tddt">开票流程包括发票申请、审核、开票、领取多个分布流程。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_cw6">
                    </div>
                    <div class="hocgl_tddh">接待管理</div>
                    <div class="hocgl_tddt">针对公司对外接待过程中，需要开销的费用实行先申请后事由的原则，接待费用申请按照相应流程进行审核。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_cw7">
                    </div>
                    <div class="hocgl_tddh">差旅报销</div>
                    <div class="hocgl_tddt">差旅费报销实行先申请出差、费用发生、报销的流程，费用超额需说明的审核步骤。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_cw8">
                    </div>
                    <div class="hocgl_tddh">费用报销</div>
                    <div class="hocgl_tddt">费用报销包括项目费用、一般费用等，严格按照流程审核步骤进行审核。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_cw9">
                    </div>
                    <div class="hocgl_tddh">领借款</div>
                    <div class="hocgl_tddt">包括领款或借款。</div>
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="hocgn==6">
            <div  class="txt42">
                数据分析
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="block3 hoc_gl61"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl62"> </div></div>
                <div class="hcell"><div class="block3 hoc_gl63"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_fx4">
                    </div>
                    <div class="hocgl_tddh">考勤分析</div>
                    <div class="hocgl_tddt">依据上班时间规划，打卡记录、请休假信息，综合统计，按月生成月度考勤报表。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_fx5">
                    </div>
                    <div class="hocgl_tddh">销售统计</div>
                    <div class="hocgl_tddt">销售工作量统计包括拜访客户量、销售额、收款额等信息。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_fx6">
                    </div>
                    <div class="hocgl_tddh">员工分析</div>
                    <div class="hocgl_tddt">根据员工基本信息进行工龄、年龄、文化程度等多角度分析。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_fx7">
                    </div>
                    <div class="hocgl_tddh">应收账款分析</div>
                    <div class="hocgl_tddt">按照客户类型、业务员、产品类型、质保年限等分别进行分析。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_fx8">
                    </div>
                    <div class="hocgl_tddh">费用分析</div>
                    <div class="hocgl_tddt">按照人员、费用类别、发生时间进行分析。</div>
                </div></div>
                <div class="hcell" style="width: 320px"><div class="hocgl_tdd">
                    <div class="blockgl_tdimg">
                    <img :src="hoc_fx9">
                    </div>
                    <div class="hocgl_tddh">客户分析</div>
                    <div class="hocgl_tddt">按照客户星级、客户类型、成交量、所属地区等多角度进行分析。</div>
                </div></div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txtzw" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="hoc_ys">
            </div>
        </section>
    </div>

</template>
<script>
    export default {
        data () {
            return {
                hocgn: 1,
                hoc_sys4: require('../assets/hoc_sys4.png'),
                hoc_sys5: require('../assets/hoc_sys5.png'),
                hoc_sys6: require('../assets/hoc_sys6.png'),
                hoc_sys7: require('../assets/hoc_sys7.png'),
                hoc_sys8: require('../assets/hoc_sys8.png'),
                hoc_sys9: require('../assets/hoc_sys9.png'),

                hoc_xz4: require('../assets/hoc_xz4.png'),
                hoc_xz5: require('../assets/hoc_xz5.png'),
                hoc_xz6: require('../assets/hoc_xz6.png'),
                hoc_xz7: require('../assets/hoc_xz7.png'),
                hoc_xz8: require('../assets/hoc_xz8.png'),
                hoc_xz9: require('../assets/hoc_xz9.png'),

                hoc_cw4: require('../assets/hoc_cw4.png'),
                hoc_cw5: require('../assets/hoc_cw5.png'),
                hoc_cw6: require('../assets/hoc_cw6.png'),
                hoc_cw7: require('../assets/hoc_cw7.png'),
                hoc_cw8: require('../assets/hoc_cw8.png'),
                hoc_cw9: require('../assets/hoc_cw9.png'),

                hoc_fx4: require('../assets/hoc_fx4.png'),
                hoc_fx5: require('../assets/hoc_fx5.png'),
                hoc_fx6: require('../assets/hoc_fx6.png'),
                hoc_fx7: require('../assets/hoc_fx7.png'),
                hoc_fx8: require('../assets/hoc_fx8.png'),
                hoc_fx9: require('../assets/hoc_fx9.png'),

                hoc_ys: require('../assets/hoc_ys.png'),
            }
        },
        mounted() {
            this.$parent.hocbanner()
        },
        computed: {

        },
        methods: {

        },
        watch: {

        },
    }
</script>
<style lang="css" scoped>
    .hoctemplate{
        color: #202020;
        text-align: left;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }

    .farm_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
        /*border: 1px solid red;*/
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .ghblockclick{
        cursor:pointer;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }

    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .hoc_gl11{background-image: url('~assets/hoc_sys1.jpg');}
    .hoc_gl12{background-image: url('~assets/hoc_sys2.jpg');}
    .hoc_gl13{background-image: url('~assets/hoc_sys3.jpg');}

    .hoc_gl21{background-image: url('~assets/hoc_xz1.jpg');}
    .hoc_gl22{background-image: url('~assets/hoc_xz2.jpg');}
    .hoc_gl23{background-image: url('~assets/hoc_xz3.jpg');}

    .hoc_gl51{background-image: url('~assets/hoc_cw1.jpg');}
    .hoc_gl52{background-image: url('~assets/hoc_cw2.jpg');}
    .hoc_gl53{background-image: url('~assets/hoc_cw3.jpg');}

    .hoc_gl61{background-image: url('~assets/hoc_fx1.jpg');}
    .hoc_gl62{background-image: url('~assets/hoc_fx2.jpg');}
    .hoc_gl63{background-image: url('~assets/hoc_fx3.jpg');}

    .hocgl_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .hocgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hocgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }

</style>
