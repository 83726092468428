import { render, staticRenderFns } from "./hoc.vue?vue&type=template&id=3850011c&scoped=true&"
import script from "./hoc.vue?vue&type=script&lang=js&"
export * from "./hoc.vue?vue&type=script&lang=js&"
import style0 from "./hoc.vue?vue&type=style&index=0&id=3850011c&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3850011c",
  null
  
)

export default component.exports